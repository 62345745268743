<template>
    <div :style="props.width ? `width: ${props.width}` : ''" class="input-container">
        <label for="d">{{ props.label }}</label>
        <div class="input-wrapper">
            <input type="text" v-bind="$attrs" :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)" />
            <div v-if="!!props.icon" class="icon-container">
                <IconifyIcon :icon="props.icon" width="40" height="40" />
            </div>
            <div v-else class="icon-container">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script lang="js">
export default {
    name: "InputTextLine",
    emits: ['update:modelValue'],
    setup(props) {
        return {
            props
        }
    },
    props: ['modelValue', 'label', 'width', 'icon']
}
</script>

<style scoped>
input,
input:focus,
input:active {
    background: none;
    border: none;
    outline: none;
    border-bottom-color: #fff;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    height: 45px;
    font-size: 16px;
    width: 100%;
    color: white
}

label {
    color: white;
}

.input-container {
    display: flex;
    flex-direction: column;
}

.input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.input-wrapper input {
    width: 100%;
    padding-right: 40px;
    box-sizing: border-box;
}

.icon-container {
    position: absolute;
    right: 0px;
    display: flex;
    align-items: center;
    margin-bottom: 32px;
}
</style>