import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'breadcrumb.home',
    component: () => import('@/layouts/AppLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/AppDashboard.vue')
      }
    ]
  },
  {
    path: '/products',
    name: 'breadcrumb.products',
    component: () => import('@/layouts/AppLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/Products/AppProducts.vue'),
      }
    ]
  },
  {
    path: '/company',
    name: 'breadcrumb.company',
    component: () => import('@/layouts/AppLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/Company/AppCompany.vue'),
      },
      {
        path: 'stores',
        name: 'breadcrumb.company_tabs.stores',
        component: () => import('@/views/Company/AppCompanyStores.vue')
      },
      {
        path: 'branches',
        name: 'breadcrumb.company_tabs.branches',
        component: () => import('@/views/Company/AppCompanyBranches.vue')
      }
    ]
  },
  {
    path: '/users',
    name: 'breadcrumb.users',
    component: () => import('@/layouts/AppLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/Users/AppUsers.vue')
      },
      {
        path: 'profiles',
        name: 'breadcrumb.users_profiles',
        component: () => import('@/views/Users/appUserProfiles.vue')
      }
    ]
  },
  {
    path: '/auth',
    name: 'breadcrumb.auth',
    component: () => import('@/layouts/AuthLayout.vue'),
    children: [
      {
        path: 'login',
        name: 'breadcrumb.auth_login',
        component: () => import('@/views/Auth/AppLogin.vue')
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: import('@/layouts/AppLayout.vue')
  },

];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;  