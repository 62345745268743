import { createI18n } from 'vue-i18n';

const messages = {
  "EN": {
    loading: "Loading...",
    select: "Select",
    success: "Success!",
    error: "Error!",
    error_img_200: "Image size must be less than 200x200 pixels",
    error_copy_enterprise_data: "Error copying enterprise data",
    copy_enterprise_data: "Click here to repeat enterprise",
    error_api_call: "Error calling API",
    success_api_call: "API call successful",
    subheader: {
      store: "Store",
      company: "Company",
      search: "Search companies...",
      search_company_error: "Error searching companies",
      search_store_error: "Error searching stores",
      empty_company: "No companies found",
      empty_store: "No stores found",
    },
    header: {
      leave: "Leave",
      notification: "Notification",
      language: "Language",
      profile: "Profile",
      invite_user: "Invite User",
      change_password: "Change Password",
    },
    sidebar: {
      open_menu: "Open menu",
      close_menu: "Close menu",
      item_company: "Company",
      item_products: "Products",
      item_users: "Users",
      item_orders: "Orders",
      item_config: "Configuration",
      item_app: "Applications",
      item_help: "Help",
    },
    breadcrumb: {
      home: "Home",
      company: "Company",
      products: "Products",
      users: "Users",
      orders: "Orders",
      monitoring: "Monitoring",
      app: "Applications",
      help: "Help",
      company_tabs: {
        stores: "Stores",
        branches: "Branches"
      },
      users_profiles: "Profiles",
    },
    company: {
      tabs: {
        company: "COMPANY",
        stores: "STORES",
        branches: "BRANCHES"
      },
      forms: {
        company: {
          corporate_name: "Corporate Name",
          corporate_name_required: "Corporate Name is required",
          trade_name: "Trade Name",
          trade_name_required: "Trade Name is required",
          sistem_name: "System Name",
          sistem_name_required: "System Name is required",
          cnpj: "CNPJ",
          cnpj_required: "CNPJ is required",
          ie: "State Registration",
          postal_code: "Postal Code",
          postal_code_required: "Postal Code is required",
          address: "Address",
          address_required: "Address is required",
          number: "Number",
          number_required: "Number is required",
          neighborhood: "Neighborhood",
          neighborhood_required: "Neighborhood is required",
          address_complement: "Complement",
          city: "City",
          city_required: "City is required",
          state: "State",
          state_required: "State is required",
          phone: "Phone",
          country: "Country",
          country_required: "Country is required",
          currency: "Currency",
          select_image: "Select an image here!",
        },
        responsible: {
          name: "Name",
          department: "Department",
          email: "E-mail",
          phone: "Phone",
          cellphone: "Mobile Phone",
          name_required: "Name is required",
          email_required: "E-mail is required",
          email_invalid: "Invalid e-mail",
          department_required: "Department is required",
        }
      },
      tables: {
        managers: {
          name: "Name",
          department: "Department",
          email: "E-mail",
          phone: "Phone",
          mobile_phone: "Mobile Phone",
          id: "ID",
        },
        stores: {
          store_id: "ID",
          store_name: "Name",
          city: "City",
          state: "State",
          responsible: "Responsible",
          email: "E-mail",
          cellphone: "Cellphone",
          phone: "Phone",
          status: "Status",
          actions: "Actions",
        }
      },
      modals: {
        delete_responsible: {
          header: "Important!",
          message: "Do you really want to delete this responsible? By clicking the Yes button, the changes will be immediately made."
        },
        inactivate_company: {
          header: "Important!",
          message: "Do you really want to inactivate this company? By clicking the Yes button, the changes will be immediately made."
        },
        new_responsible: {
          header: "New Responsible",
          button_cancel: "Cancel",
          button_save: "Save",
          button_back: "Return"
        },
        edit_reponsible: {
          header: "Edit Responsible",
          button_cancel: "Cancel",
          button_save: "Save",
        }
      },
      button_yes: "Yes",
      button_no: "No",
      active: "active",
      inactive: "inactive",
      button_edit: "Edit",
      button_new_responsible: "New Responsible",
      general_information: "GENERAL INFORMATION",
      managers: "Managers",
      no_stores: "No stores registered",
      no_stores_description: "Register a new store by clicking the button above",
    },
    company_store: {
      forms: {
        new_store: {
          store_name: "Store Name",
          responsible: "Responsible",
          email: "E-mail",
          phone: "Phone",
          city: "City",
          state: "State",
          cellphone: "Cellphone",
        },
      },
      modals: {
        new_store: {
          header: "New Store",
          button_cancel: "Cancel",
          button_save: "Save",
          store_name_required: "Store name is required",
          city_required: "City is required",
          state_required: "State is required",
          email_invalid: "Invalid e-mail",
        },
        edit_store: {
          header: "Edit Store",
        }
      },
      store_list: "STORE LIST",
      button_filter: "Filters",
      button_add_store: "Add Store",
      fetch_store_error: "Error searching stores",
      error_no_enterprise: "No enterprise selected",
    },
    company_branches: {
      tables: {
        branches: {
          id: "ID",
          name: "Name",
          tax_id: "CNPJ",
          type: "Branch Type",
          city: "City",
          state: "State",
          responsible: "Responsible",
          store: "Store",
          status: "Status",
          actions: "Actions",
          branch_types: {
            physical_store: "Physical Store",
            pickup_point: "Pickup Point",
            distribution_center: "Distribution Center",
            head_office: "Head Office",
            fulfillment_center: "Fulfillment Center",
          }
        }
      },
      modals: {
        new_branch: {
          header: "New Branch",
          button_cancel: "Cancel",
          button_save: "Save",
        },
        edit_branch: {
          header: "Edit Branch",
          button_cancel: "Cancel",
          button_save: "Save",
        }
      },
      forms: {
        new_branch: {
          tax_identifier: "CNPJ",
          tax_identifier_required: "CNPJ is required",
          branch_name: "Branch Name",
          branch_name_required: "Branch Name is required",
          postal_code: "Postal Code",
          postal_code_required: "Postal Code is required",
          address: "Address",
          address_required: "Address is required",
          address_complement: "Complement",
          city: "City",
          city_required: "City is required",
          state: "State",
          state_required: "State is required",
          state_placeholder: "Select a state",
          state_registration : "State Registration",
          responsable: "Responsable",
          store: "Stores",
          store_required: "Store is required",
          store_placeholder: "Select a store",
          stores_no_result: "No stores found",
          branch_type : "Branch Type",
          number: "Number",
          number_required: "Number is required",
          branch_type_placeholder: "Select a branch type",
          branch_type_required: "Branch Type is required",
          branch_types: {
            physical_store: "Physical Store",
            pickup_point: "Pickup Point",
            distribution_center: "Distribution Center",
            head_office: "Head Office",
            fulfillment_center: "Fulfillment Center",
          }
        }
      },
      error_search_branches: "Error searching branches",
      empty_branches: "No branches found",
      empty_branches_description: "Register a new branch by clicking the button above",
      filial_list: "BRANCH LIST",
      button_add_branch: "Add Branch"
    },
    users: {
      tables: {
        users: {
          id: "ID",
          name: "Name",
          email: "E-mail",
          role: "Role",
          department: "Department",
          status: "Status",
          actions: "Actions",
        }
      },
      modals: {

      },
      forms: {
        new_user: {
          name: "Name",
          email: "E-mail",
          profile: "Access Profile",
          stores: "Stores",
          realesed_stores: "Released Stores",
          department: "Department",
          name_required: "Name is required",
          email_required: "E-mail is required",
          email_invalid: "Invalid e-mail",
          stores_required: "Stores is required",
          department_required: "Department is required",
          profile_required: "Profile is required",
        }
      },
      no_users: "No users found",
      no_users_description: "Register a new user by clicking the button above",
      fetch_users_error: "Error fetching users",
      users_list: "USERS LIST",
      search: "Search users...",
      button_access_profiles: "Access Profiles",
      button_add_user: "Add User",
    },
    users_profiles: {
      profile_management: "PROFILE MANAGEMENT",
      button_duplicate_profile: "Duplicate Profile",
      button_add_profile: "Add Profile",
    },
    auth: {
      login: {
        email: "E-mail",
        password: "Password",
        no_account: "Don't have an account yet?",
        no_account_click_here: "Click here",
        forgot_my_password: "Forgot my password",
        log_in: "LOG IN"
      }
    }
  },
  "pt_BR": {
    loading: "Carregando...",
    select: "Selecione",
    success: "Sucesso!",
    error: "Erro!",
    error_img_200: "Imagem deve ter menos de 200x200 pixels",
    error_copy_enterprise_data: "Erro ao copiar dados da empresa",
    copy_enterprise_data: "Clique aqui para repetir os dados da empresa",
    error_api_call: "Erro ao chamar API",
    success_api_call: "Chamada API bem-sucedida",
    subheader: {
      store: "Loja",
      company: "Empresa",
      search: "Pesquisar empresas...",
      search_company_error: "Erro ao buscar empresas",
      search_store_error: "Erro ao buscar lojas",
      empty_company: "Nenhuma empresa encontrada",
      empty_store: "Nenhuma loja encontrada",
    },
    header: {
      leave: "Sair",
      notification: "Notificação",
      language: "Idioma",
      profile: "Perfil",
      invite_user: "Convidar Usuário",
      change_password: "Alterar Senha",
    },
    sidebar: {
      open_menu: "Abrir menu",
      close_menu: "Fechar menu",
      item_company: "Empresa",
      item_products: "Produtos",
      item_users: "Usuários",
      item_orders: "Pedidos",
      item_config: "Configurações",
      item_app: "Aplicativos",
      item_help: "Ajuda",
    },
    breadcrumb: {
      home: "Home",
      company: "Empresa",
      products: "Produtos",
      users: "Usuários",
      orders: "Pedidos",
      monitoring: "Monitoramento",
      app: "Aplicativos",
      help: "Ajuda",
      company_tabs: {
        stores: "Lojas",
        branches: "Filiais"
      },
      users_profiles: "Perfis",
    }, 
    company: {
      tabs: {
        company: "EMPRESA",
        stores: "LOJAS",
        branches: "FILIAIS",
      },
      forms: {
        company: {
          corporate_name: "Razão Social",
          corporate_name_required: "Razão Social é obrigatório",
          trade_name: "Nome Fantasia",
          trade_name_required: "Nome Fantasia é obrigatório",
          sistem_name: "Nome no Sistema",
          sistem_name_required: "Nome no Sistema é obrigatório",
          cnpj: "CNPJ",
          cnpj_required: "CNPJ é obrigatório",
          ie: "Inscrição Estadual",
          postal_code: "CEP",
          postal_code_required: "CEP é obrigatório",
          address: "Endereço",
          address_required: "Endereço é obrigatório",
          number: "Número",
          number_required: "Número é obrigatório",
          neighborhood: "Bairro",
          neighborhood_required: "Bairro é obrigatório",
          address_complement: "Complemento",
          city: "Cidade",
          city_required: "Cidade é obrigatório",
          state: "Estado",
          state_required: "Estado é obrigatório",
          phone: "Telefone",
          country: "País",
          country_required: "País é obrigatório",
          currency: "Moeda",
          select_image: "Selecione uma imagem aqui!",
        },
        responsible: {
          name: "Nome",
          department: "Departamento",
          email: "E-mail",
          phone: "Telefone",
          cellphone: "Celular",
          name_required: "Nome é obrigatório",
          email_required: "E-mail é obrigatório",
          department_required: "Departamento é obrigatório",
        }
      },
      tables: {
        managers: {
          name: "Nome",
          department: "Departamento",
          email: "E-mail",
          phone: "Telefone",
          mobile_phone: "Celular",
          id: "Código",
        },
        stores: {
          store_id: "Cód.",
          store_name: "Nome",
          city: "Cidade",
          state: "Estado",
          responsible: "Responsável",
          email: "E-mail",
          cellphone: "Celular",
          phone: "Telefone",
          status: "Status",
          actions: "Ações",
        }
      },
      modals: {
        delete_responsible: {
          header: "Importante!",
          message: "Deseja realmente excluir esse responsável? Ao clicar no botão Sim, as alterações serão imediatamente realizadas."
        },
        inactivate_company: {
          header: "Importante!",
          message: "Deseja realmente inativar essa empresa? Ao clicar no botão Sim, as alterações serão imediatamente realizadas."
        },
        new_responsible: {
          header: "Novo Responsável",
          button_cancel: "Cancelar",
          button_save: "Salvar",
          button_back: "Voltar"
        },
        edit_reponsible: {
          header: "Editar Responsável",
          button_cancel: "Cancelar",
          button_save: "Salvar",
        }
      },
      button_yes: "Sim",
      button_no: "Não",
      active: "ativo",
      inactive: "inativo",
      button_edit: "Editar",
      button_new_responsible: "Novo Responsável",
      general_information: "DADOS GERAIS DA EMPRESA",
      managers: "Responsáveis",
      no_stores: "Nenhuma loja cadastrada",
      no_stores_description: "Cadastre uma nova loja clicando no botão acima",
    },
    company_store: {
      forms: {
        new_store: {
          store_name: "Nome da Loja",
          responsible: "Responsável",
          email: "E-mail",
          phone: "Telefone",
          city: "Cidade",
          state: "Estado",
          cellphone: "Celular",
        }
      },
      modals: {
        new_store: {
          header: "Nova Loja",
          button_cancel: "Cancelar",
          button_save: "Salvar",
          store_name_required: "Nome da loja é obrigatório",
          city_required: "Cidade é obrigatório",
          state_required: "Estado é obrigatório",
          email_invalid: "E-mail inválido",
        },
        edit_store: {
          header: "Editar Loja",
        }
      },
      store_list: "LISTAGEM DE LOJAS",
      button_filter: "Filtros",
      button_add_store: "Adicionar Loja",
      fetch_store_error: "Erro ao buscar lojas",
      error_no_enterprise: "Nenhuma empresa selecionada",
    },
    company_branches: {
      tables: {
        branches: {
          id: "Código",
          name: "Nome",
          tax_id: "CNPJ",
          type: "Tipo de filial",
          city: "Cidade",
          state: "UF",
          responsible: "Responsável",
          store: "Loja",
          status: "Status",
          actions: "Ações",
          branch_types: {
            physical_store: "Loja Física",
            pickup_point: "Ponto de Retirada",
            distribution_center: "Centro de Distribuição",
            head_office: "Matriz",
            fulfillment_center: "Centro de Fulfillment",
          }
        }
      },
      modals: {
        new_branch: {
          header: "Nova Filial",
          button_cancel: "Cancelar",
          button_save: "Salvar",
        },
        edit_branch: {
          header: "Editar Filial",
          button_cancel: "Cancelar",
          button_save: "Salvar",
        }
      },
      forms: {
        new_branch: {
          tax_identifier: "CNPJ",
          tax_identifier_required: "CNPJ é obrigatório",
          branch_name: "Nome da Filial",
          branch_name_required: "Nome da Filial é obrigatório",
          postal_code: "CEP",
          postal_code_required: "CEP é obrigatório",
          address: "Endereço",
          address_required: "Endereço é obrigatório",
          address_complement: "Complemento",
          city: "Cidade",
          city_required: "Cidade é obrigatório",
          state: "Estado",
          state_required: "Estado é obrigatório",
          state_placeholder: "Selecione um estado",
          state_registration : "Inscrição Estadual",
          responsable: "Responsável",
          store: "Lojas",
          store_required: "Loja é obrigatório",
          store_placeholder: "Selecione uma loja",
          stores_no_result: "Nenhuma loja encontrada",
          branch_type : "Tipo de Filial",
          number: "Número",
          number_required: "Número é obrigatório",
          branch_type_placeholder: "Selecione um tipo de filial",
          branch_type_required: "Tipo de Filial é obrigatório",
          branch_types: {
            physical_store: "Loja Física",
            pickup_point: "Ponto de Retirada",
            distribution_center: "Centro de Distribuição",
            head_office: "Matriz",
            fulfillment_center: "Centro de Fulfillment",
          }
        }
      },
      error_search_branches: "Erro ao buscar filiais",
      empty_branches: "Nenhuma filial encontrada",
      empty_branches_description: "Cadastre uma nova filial clicando no botão acima",
      filial_list: "LISTAGEM DE FILIAIS",
      button_add_branch: "Adicionar Filial"
    },
    users: {
      tables: {
        users: {
          id: "ID",
          name: "Nome",
          email: "E-mail",
          role: "Perfil",
          department: "Departamento",
          status: "Status",
          actions: "Ações",
        }
      },
      modals: {

      },
      forms: {
        new_user: {
          name: "Nome",
          email: "E-mail",
          profile: "Perfil de Acesso",
          stores: "Lojas",
          realesed_stores: "Lojas Liberadas",
          department: "Departamento",
          name_required: "Nome é obrigatório",
          email_required: "E-mail é obrigatório",
          email_invalid: "E-mail inválido",
          department_required: "Departamento é obrigatório",
          profile_required: "Perfil é obrigatório",
        }
      },
      no_users: "Nenhum usuário encontrado",
      no_users_description: "Cadastre um novo usuário clicando no botão acima",
      fetch_users_error: "Erro ao buscar usuários",
      users_list: "LISTAGEM DE USUÁRIOS",
      search: "Pesquisar usuários...",
      button_access_profiles: "Perfis de Acesso",
      button_add_user: "Adicionar Usuário",
    },
    users_profiles: {
      profile_management: "GESTÃO DE PERFIS",
      button_duplicate_profile: "Duplicar Perfil",
      button_add_profile: "Adicionar Perfil",
    },
    auth: {
      login: {
        email: "E-mail",
        password: "Senha",
        no_account: "Ainda não possui uma conta?",
        no_account_click_here: "Clique aqui",
        forgot_my_password: "Esqueci minha senha",
        log_in: "INICIAR SESSÃO"
      }
    }
  },
  "ES": {
    loading: "Cargando...",
    select: "Seleccionar",
    success: "¡Éxito!",
    error: "Error!",
    error_img_200: "La imagen debe tener menos de 200x200 píxeles",
    error_copy_enterprise_data: "Error al copiar los datos de la empresa",
    copy_enterprise_data: "Haga clic aquí para repetir la empresa",
    error_api_call: "Error al llamar a la API",
    success_api_call: "Llamada API exitosa",
    subheader: {
      store: "Tienda",
      company: "Empresa",
      search: "Buscar empresas...",
      search_company_error: "Error al buscar empresas",
      search_store_error: "Error al buscar tiendas",
      empty_company: "No se encontraron empresas",
      empty_store: "No se encontraron tiendas",
    },
    header: {
      leave: "Salir",
      notification: "Notificación",
      language: "Idioma",
      profile: "Perfil",
      invite_user: "Invitar Usuario",
      change_password: "Cambiar Contraseña",
    },
    sidebar: {
      open_menu: "Abrir menú",
      close_menu: "Cerrar menú",
      item_company: "Compañía",
      item_products: "Productos",
      item_users: "Usuarios",
      item_orders: "Pedidos",
      item_config: "Configuración",
      item_app: "Aplicaciones",
      item_help: "Ayuda",
    },
    breadcrumb: {
      home: "Home",
      company: "Compañía",
      products: "Productos",
      users: "Usuarios",
      orders: "Pedidos",
      monitoring: "Monitoreo",
      app: "Aplicaciones",
      help: "Ayuda",
      company_tabs: {
        stores: "Tiendas",
        branches: "Sucursales"
      },
      users_profiles: "Perfiles",
    },
    company: {
      tabs: {
        company: "COMPAÑÍA",
        stores: "TIENDAS",
        branches: "SUCURSALES",
      },
      forms: {
        company: {
          corporate_name: "Razón Social",
          corporate_name_required: "Razón Social es obligatorio",
          trade_name: "Nombre Fantasía",
          trade_name_required: "Nombre Fantasía es obligatorio",
          sistem_name: "Nombre en el Sistema",
          sistem_name_required: "Nombre en el Sistema es obligatorio",
          cnpj: "CNPJ",
          cnpj_required: "CNPJ es obligatorio",
          ie: "Inscripción Estatal",
          postal_code: "Código Postal",
          postal_code_required: "Código Postal es obligatorio",
          address: "Dirección",
          address_required: "Dirección es obligatorio",
          number: "Número",
          number_required: "Número es obligatorio",
          neighborhood: "Barrio",
          neighborhood_required: "Barrio es obligatorio",
          address_complement: "Complemento",
          city: "Ciudad",
          city_required: "Ciudad es obligatorio",
          state: "Estado",
          state_required: "Estado es obligatorio",
          phone: "Teléfono",
          country: "País",
          country_required: "País es obligatorio",
          currency: "Moneda",
          select_image: "Seleccione una imagen aquí!",
        },
        responsible: {
          name: "Nombre",
          department: "Departamento",
          email: "E-mail",
          phone: "Teléfono",
          cellphone: "Celular",
          name_required: "Nombre es obligatorio",
          email_required: "E-mail es obligatorio",
          email_invalid: "E-mail inválido",
          department_required: "Departamento es obligatorio",
        }
      },
      tables: {
        managers: {
          name: "Nombre",
          department: "Departamento",
          email: "E-mail",
          phone: "Teléfono",
          cellphone: "Celular",
          id: "Código"
        },
        stores: {
          store_id: "Cód.",
          store_name: "Nombre",
          city: "Ciudad",
          state: "Estado",
          responsible: "Responsable",
          email: "E-mail",
          cellphone: "Celular",
          phone: "Teléfono",
          status: "Estado",
          actions: "Acciones",
        }
      },
      modals: {
        delete_responsible: {
          header: "¡Importante!",
          message: "¿Realmente desea eliminar este responsable? Al hacer clic en el botón Sí, los cambios se realizarán de inmediato."
        },
        inactivate_company: {
          header: "¡Importante!",
          message: "¿Realmente desea inactivar esta empresa? Al hacer clic en el botón Sí, los cambios se realizarán de inmediato." 
        },
        new_responsible: {
          header: "Nuevo Responsable",
          button_cancel: "Cancelar",
          button_save: "Guardar",
          button_back: "Volver"
        },
        edit_reponsible: {
          header: "Editar Responsable",
          button_cancel: "Cancelar",
          button_save: "Guardar",
        }
      },
      button_yes: "Sí",
      button_no: "No",
      active: "activo",
      inactive: "inactivo",
      button_edit: "Editar",
      button_new_responsible: "Nuevo Responsable",
      general_information: "INFORMACIÓN GENERAL",
      managers: "Responsables",
      no_stores: "No hay tiendas registradas",
      no_stores_description: "Registre una nueva tienda haciendo clic en el botón de arriba",
    },
    company_store: {
      forms: {
        new_store: {
          store_name: "Nombre de la Tienda",
          responsible: "Responsable",
          email: "E-mail",
          phone: "Teléfono",
          city: "Ciudad",
          state: "Estado",
          cellphone: "Celular",
        }
      },
      modals: {
        new_store: {
          header: "Nueva Tienda",
          button_cancel: "Cancelar",
          button_save: "Guardar",
          store_name_required: "Nombre de la tienda es obligatorio",
          city_required: "Ciudad es obligatorio",
          state_required: "Estado es obligatorio",
          email_invalid: "E-mail inválido",
        },
        edit_store: {
          header: "Editar Tienda",
        }
      },
      store_list: "LISTADO DE TIENDAS",
      button_filter: "Filtros",
      button_add_store: "Agregar Tienda",
      fetch_store_error: "Error al buscar tiendas",
      error_no_enterprise: "No se ha seleccionado ninguna empresa",
    },
    company_branches: {
      tables: {
        branches: {
          id: "Código",
          name: "Nombre",
          tax_id: "CNPJ",
          type: "Tipo de sucursal",
          city: "Ciudad",
          state: "Estado",
          responsible: "Responsable",
          store: "Tienda",
          status: "Estado",
          actions: "Acciones",
          branch_types: {
            physical_store: "Tienda Física",
            pickup_point: "Punto de Retiro",
            distribution_center: "Centro de Distribución",
            head_office: "Sede",
            fulfillment_center: "Centro de Fulfillment",
          }
        }
      },
      modals: {
        new_branch: {
          header: "Nueva Sucursal",
          button_cancel: "Cancelar",
          button_save: "Guardar",
        },
        edit_branch: {
          header: "Editar Sucursal",
          button_cancel: "Cancelar",
          button_save: "Guardar",
        }
      },
      forms: {
        new_branch: {
          tax_identifier: "CNPJ",
          tax_identifier_required: "CNPJ es obligatorio",
          branch_name: "Nombre de la Sucursal",
          branch_name_required: "Nombre de la Sucursal es obligatorio",
          postal_code: "Código Postal",
          postal_code_required: "Código Postal es obligatorio",
          address: "Dirección",
          address_required: "Dirección es obligatorio",
          address_complement: "Complemento",
          city: "Ciudad",
          city_required: "Ciudad es obligatorio",
          state: "Estado",
          state_required: "Estado es obligatorio",
          state_placeholder: "Seleccione un estado",
          state_registration : "Inscripción Estatal",
          responsable: "Responsable",
          store: "Tiendas",
          store_required: "Tienda es obligatorio",
          store_placeholder: "Seleccione una tienda",
          stores_no_result: "No hay tiendas registradas",
          branch_type : "Tipo de Sucursal",
          number: "Número",
          number_required: "Número es obligatorio",
          branch_type_placeholder: "Seleccione un tipo de sucursal",
          branch_type_required: "Tipo de Sucursal es obligatorio",
          branch_types: {
            physical_store: "Tienda Física",
            pickup_point: "Punto de Retiro",
            distribution_center: "Centro de Distribución",
            head_office: "Sede",
            fulfillment_center: "Centro de Fulfillment",
          }
        }
      },
      error_search_branches: "Error al buscar sucursales",
      empty_branches: "No se encontraron sucursales",
      empty_branches_description: "Registre una nueva sucursal haciendo clic en el botón de arriba",
      filial_list: "LISTADO DE SUCURSALES",
      button_add_branch: "Agregar Sucursal"
    },
    users: {
      tables: {
        users: {
          id: "Código",
          name: "Nombre",
          email: "E-mail",
          role: "Perfil",
          department: "Departamento",
          status: "Estado",
          actions: "Acciones",
        }
      },
      modals: {

      },
      forms: {
        new_user: {
          name: "Nombre",
          email: "E-mail",
          profile: "Perfil de Acceso",
          stores: "Tiendas",
          realesed_stores: "Tiendas Liberadas",
          department: "Departamento",
          name_required: "Nombre es obligatorio",
          email_required: "E-mail es obligatorio",
          email_invalid: "E-mail inválido",
          department_required: "Departamento es obligatorio",
          profile_required: "Perfil es obligatorio",
        }
      },
      no_users: "No se encontraron usuarios",
      no_users_description: "Registre un nuevo usuario haciendo clic en el botón de arriba",
      fetch_users_error: "Error al buscar usuarios",
      users_list: "LISTADO DE USUARIOS",
      search: "Buscar usuarios...",
      button_access_profiles: "Perfiles de Acceso",
      button_add_user: "Agregar Usuario",
    },
    users_profiles: {
      profile_management: "GESTIÓN DE PERFILES",
      button_duplicate_profile: "Duplicar Perfil",
      button_add_profile: "Agregar Perfil",
    },
    auth: {
      login: {
        email: "E-mail",
        password: "Contraseña",
        no_account: "¿Aún no tienes una cuenta?",
        no_account_click_here: "Haz clic aquí",
        forgot_my_password: "Olvidé mi contraseña",
        log_in: "INICIAR SESIÓN"
      }
    }
  }
}

const i18n = createI18n({
  locale: 'pt_BR', 
  fallbackLocale: 'EN', 
  messages,
});

export default i18n;